<template>
  <div class="flex flex-col gap-3">
    <div class="flex justify-end">
      <p>Total Proposal Value : {{ priceFormat(totalProposalValue) }}</p>
    </div>
    <div class="vx-row mb-12" v-if="showTab">
      <div
        v-bind:class="[
          detail ? detailShow + ' vertical-divider' : '',
          detailHide,
        ]"
      >
        <div class="flex flex-row gap-4 w-full mb-6 mt-2">
          <vs-button v-on:click="printPDF(false)">Print</vs-button>
          <vs-button v-on:click="openModal()">Clearing With DN</vs-button>
          <vs-button @click="createPayment">Group</vs-button>
          <!-- <vs-button @click="showPaymentCodePopup = !showPaymentCodePopup"
          >dummy</vs-button
        > -->
        </div>

        <clearing-modal
          :isOpen="isModalOpen"
          :selectedProposal="checkedCode"
          :poType="poType"
          @closeModal="closeModal($event)"
          @closeModalWithReload="closeModalWithReload($event)"
        />

        <data-table
          :responseData="responseData"
          :propsParams="params"
          :header="header"
          @reloadDataFromChild="reloadData"
        >
          <template slot="thead">
            <th width="7%">Check</th>
            <th width="7%">Action</th>
          </template>
          <template slot="tbody">
            <vs-tr
              :key="indextr"
              v-for="(tr, indextr) in responseData.purchasePaymentProposal"
              :class="tr.class"
            >
              <vs-td>
                <vs-checkbox
                  :checked="checked.includes(data[indextr].ID)"
                  v-on:click="addChecked(data[indextr])"
                />
              </vs-td>
              <vs-td class="whitespace-no-wrap">
                <template>
                  <div class="mt-4 flex justify-between">
                    <vx-tooltip text="Cancel Payment Proposal" class="mr-4">
                      <vs-button
                        type="line"
                        color="red"
                        icon-pack="feather"
                        icon="icon-x"
                        v-on:click.stop="doCancel(tr)"
                      />
                    </vx-tooltip>
                    <vx-tooltip
                      :text="
                        tr.po_type != 'CN Supplier' ? 'Show Invoice' : 'Show'
                      "
                      class="mr-4"
                    >
                      <vs-button
                        type="line"
                        icon-pack="feather"
                        icon="icon-eye"
                        v-on:click.stop="handleShow(tr)"
                      />
                    </vx-tooltip>
                  </div>
                </template>
              </vs-td>

              <vs-td>
                {{ tr.Code }}
              </vs-td>
              <vs-td>
                Code : {{ tr.SupplierCode }}<br />
                Name : {{ tr.SupplierName }}
              </vs-td>
              <vs-td>
                {{ tr.DeliveryNumber }}
              </vs-td>
              <vs-td>
                <p v-if="tr.po_type != 'CN Supplier'">
                  External Number : {{ tr.PurchaseInvoiceCode }}
                </p>
                <p v-if="tr.po_type != 'CN Supplier'">
                  Internal Number : {{ tr.PurchaseInvoiceCodeInternal }}
                </p>
                <p v-if="tr.po_type != 'CN Supplier'">
                  Total Value : {{ priceFormat(tr.PurchaseInvoiceValue) }}
                </p>
                <p v-if="tr.po_type != 'CN Supplier'">
                  Due Date : {{ dateFormat(tr.DueDate) }}
                </p>
                <p>
                  Plan Payment Date : {{ dateFormat(tr.PurposePaymentDate) }}
                </p>
                <p>Type : {{ tr.po_type }}</p>
              </vs-td>
              <vs-td> {{ priceFormat(tr.PaidValue) }}<br /> </vs-td>
            </vs-tr>
          </template>
        </data-table>
        <!-- Approve Prompt -->
        <vs-prompt
          title="Confirmation"
          color="primary"
          @accept="doYes"
          @cancel="doNo"
          @close="closeDetail"
          :accept-text="'Yes'"
          :cancel-text="'No'"
          :buttons-hidden="false"
          :active.sync="confirmation"
        >
          <div class="con-exemple-prompt">
            Create Payment
            <br />

            Process due date only?
            <br />
            <br />
            <!-- <vs-textarea v-model="notes"/> -->
          </div>
        </vs-prompt>
        <vs-popup
          fullscreen
          title="fullscreen"
          :active.sync="showPdf"
          :button-close-hidden="false"
        >
          <div class="flex flex-col gap-6 h-full">
            <iframe
              :src="pdfUrlPopup"
              class="w-full"
              style="min-height: 80vh"
            />
            <div class="flex gap-3 w-full justify-end">
              <vs-button color="danger" @click="handleClosePopUp"
                >Cancel</vs-button
              >
              <vs-Button @click="handlePrintPDFPreview">Print</vs-Button>
            </div>
          </div>
        </vs-popup>

        <vs-popup
          title="Created Grup Code"
          :active.sync="showPaymentCodePopup"
          :button-close-hidden="false"
        >
          <div class="flex flex-col gap-6 h-full justify-center items-center">
            <h4 class="text-primary">Group creation success</h4>
            <div style="width: 100%; max-height: 250px; overflow: auto">
              <vs-table :data="createdPaymentCodes">
                <template slot="thead">
                  <vs-th> Codes </vs-th>
                </template>

                <template slot-scope="{ data }">
                  <vs-tr :key="indextr" v-for="(tr, indextr) in data">
                    <vs-td :data="tr">
                      {{ tr }}
                    </vs-td>
                  </vs-tr>
                </template>
              </vs-table>
            </div>
            <div class="w-full justify-end flex">
              <vs-button color="danger" @click="showPaymentCodePopup = false"
                >Close</vs-button
              >
            </div>
          </div>
        </vs-popup>

        <vs-prompt
          title="Print Confirmation"
          color="primary"
          @accept="printPDF(true)"
          @cancel="closeReprintPrompt"
          @close="closeReprintPrompt"
          :accept-text="'Yes'"
          :cancel-text="'No'"
          :buttons-hidden="false"
          :active.sync="reprintPrompt"
        >
          <div class="font-bold" style="width: fit-content">
            <span>{{ reprintMessage }}</span>
            <br />
            <span>Are you sure ?</span>
          </div>
        </vs-prompt>
      </div>
      <!-- form -->
      <transition name="detail-fade">
        <div
          v-if="detail"
          v-bind:class="[
            detail ? detailShow + 'md:w-2/3 w-full mb-base' : '',
            detailHide,
          ]"
        >
          <vs-row>
            <vs-col
              style="padding-bottom: 8px"
              vs-offset="8"
              vs-type="flex"
              vs-justify="rigth"
              vs-align="rigth"
              vs-w="4"
            >
              <vs-button
                class="ml-auto"
                size="small"
                v-on:click="handleClose"
                color="grey"
                icon-pack="feather"
                icon="icon-x-square"
                >Close</vs-button
              >
            </vs-col>
            <vs-col
              vs-offset="0"
              vs-type="flex"
              vs-justify="center"
              vs-align="center"
              vs-w="1"
            >
              <vs-button
                size="small"
                v-on:click="handleClose"
                color="grey"
                icon-pack="feather"
                icon="icon-x-square"
              ></vs-button>
            </vs-col>
            <detail-div
              v-if="poType !== 'CN Supplier'"
              @close="handleClose"
              :selected="selectedData"
            />
            <detail-cn-supplier
              v-else
              @close="handleClose"
              :selected="selectedData"
            />
          </vs-row>
        </div>
      </transition>
    </div>
  </div>
</template>
<script>
import detail from "./detail.vue";
import cnDetail from "./detail-cn-supplier.vue";
import moment from "moment";
import clearingModal from "./clearing-modal.vue";
import { jsPDF } from "jspdf";
import autoTable from "jspdf-autotable";
import angkaTerbilang from "@develoka/angka-terbilang-js";
// import { dataPaymentCollection } from "../../../../../services/api/invoice";
export default {
  components: {
    "detail-div": detail,
    "clearing-modal": clearingModal,
    "detail-cn-supplier": cnDetail,
  },
  props: {
    selected: Object,
    option: Object,
    codes: {
      type: Array,
      default: () => [],
    },
    showTab: Boolean,
  },
  data() {
    return {
      params: {
        search: "",
        length: 10,
        page: 1,
        order: "desc",
        sort: "id",
      },
      checked: [],
      checkedCode: [],
      confirmation: false,
      isModalOpen: false,
      header: [
        {
          text: "Code",
          value: "code",
          // width: '5%'
        },
        {
          text: "Supplier",
          value: "supplier_code",
        },
        {
          text: "No Shipment",
          value: "delivery_number",
        },
        {
          text: "Invoice",
          value: "purchase_invoice_code",
        },
        {
          text: "Value",
          sortable: false,
        },
      ],
      responseData: {},
      detailShow: "vx-col md:w-1/2 w-full mb-base",
      detailHide: "core vx-col md:w-1/1 w-full mb-base",
      detail: false,
      notes: "",
      approvalPrompt: false,
      rejectPrompt: false,
      selectedData: {},
      pdfUrlPopup: "",
      showPdf: false,
      pdfProxyClass: null,
      contentHeight: "",
      rowHeight: "",
      createdPaymentCodes: [
        // "a3f7Yz9Wq4T2",
        // "b9Wq4Tc6D1Lp",
        // "c6D1Lp8J2Fk5",
        // "d8J2Fk5M0Hv7",
        // "e5M0Hv7Q3Gs6",
        // "f7Q3Gs4N6Bw8",
        // "g4N6Bw2Z8Cx1",
        // "h2Z8Cx1X5Rm0",
        // "i1X5Rm0V9Tp3",
        // "j0V9Tp3a3f7Y",
      ],
      showPaymentCodePopup: false,
      multipleReprint: false,
      reprintPrompt: false,
      proposals: [],
      usingCloseButton: false,
      poType: "",
    };
  },
  computed: {
    reprintMessage() {
      return !this.multipleReprint
        ? "You are going to reprint this proposal."
        : "Some of the selected proposal has been printed and will be reprinted.";
    },
    totalProposalValue() {
      let totalValue = 0;
      // if (!this.responseData || Object.keys(this.responseData).length === 0) {
      //   return totalValue;
      // }
      // totalValue = this.responseData.purchasePaymentProposal.reduce(
      //   (acc, item) => {
      //     return acc + item.PaidValue;
      //   },
      //   0
      // );

      this.checkedCode.forEach((item) => {
        totalValue += item.PaidValue;
      });
      return totalValue;
    },
  },
  watch: {
    showPdf() {
      if (this.showPdf) {
        return;
      }

      if (!this.usingCloseButton) {
        this.showPdf = true;
      } else {
        this.usingCloseButton = false;
      }
    },
    codes() {
      if (!this.showTab) {
        return;
      }
      this.reloadData(this.params);
    },
  },
  async mounted() {
    // this.reloadData(this.params);
    this.contentHeight = `calc(${window.innerHeight}px - 80px)`;
    this.rowHeight = `calc(${window.innerHeight}px - 280px)`;
  },
  methods: {
    doCancel(selected) {
      // console.log(selected)
      this.$vs.loading();
      const params = {
        status: 3,
        propose_id: selected.ID,
        purchase_invoice_id: selected.PurchaseInvoiceID,
        notes: "",
      };

      this.$http
        .post("/api/v1/purchase-payment-proposal/change-status", params)
        .then((resp) => {
          this.$vs.loading.close();
          if (resp.code == 200) {
            this.handleClose();

            this.$vs.notify({
              color: "success",
              title: "Success",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });

            // this.createdPaymentCodes = resp.data;
            // this.showPaymentCodePopup = true;
          } else {
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          }
        })
        .catch((e) => {
          this.$vs.loading.close();
          this.$vs.notify({
            color: "danger",
            title: "Error",
            text: "error catch",
            position: "top-right",
            iconPack: "feather",
            icon: "icon-x-circle",
          });
          console.log(e);
        });
    },
    doYes() {
      console.log("checked code", this.checkedCode);
      console.log("checked code", this.checked);
      // this.checkedCode.map((v) => {
      //   if (
      //     parseInt(moment(v.DueDate).format("YYYYMMDD")) >
      //     parseInt(moment().format("YYYYMMDD"))
      //   ) {
      //     console.log("masuk");
      //     this.checked = this.checked.filter((subv) => {
      //       return v.ID != subv;
      //     });
      //   }
      // });
      if (this.checked.length > 0) {
        this.doGenerate();
      } else {
        this.$vs.notify({
          color: "danger",
          title: "Error",
          text: "not found due date only on selected data",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-x-circle",
        });
      }
      // console.log(this.checked)
    },
    doNo() {
      this.doGenerate();
      // console.log('no')
    },
    doGenerate() {
      let params = {
        proposal_id: this.checked,
        type: this.poType,
      };
      this.$vs.loading();
      this.$http
        .post("/api/v1/purchase-payment-request/generate", params)
        .then((r) => {
          // console.log(r)
          this.$vs.loading.close();
          if (r.code == 200) {
            this.$vs.notify({
              color: "success",
              title: "Success",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
            this.createdPaymentCodes = r.data;
            this.showPaymentCodePopup = true;
          } else {
            this.$vs.notify({
              color: "danger",
              title: r.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          }
          this.$nextTick(() => {
            this.checked = [];
            this.checkedCode = [];
            this.reloadData(this.params);
          });
        })
        .catch((e) => {
          this.$vs.notify({
            color: "danger",
            title: "Error",
            position: "top-right",
            iconPack: "feather",
            icon: "icon-x-circle",
          });
          this.$vs.loading.close();
        });
    },
    addChecked(val) {
      if (this.checked.includes(val.ID)) {
        this.checked.splice(this.checked.indexOf(val.ID), 1);
        this.checkedCode = this.checkedCode.filter((v) => {
          return v.ID != val.ID;
        });
        // this.checkedCode.splice(this.checkedCode.indexOf(val.Code), 1);
      } else {
        this.checked.push(val.ID);
        this.checkedCode.push(val);
      }
    },
    createPayment() {
      console.log("here gess");
      if (this.checked.length === 0 || this.checkedCode.length === 0) {
        this.$vs.notify({
          color: "danger",
          title: "Create Payment",
          text: "Please select at least one proposal to be processed",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-x-circle",
        });
        return;
      }

      if (!this.validateSupplier()) {
        return;
      }

      const poType = this.checkedCode[0].po_type;
      if (!this.checkedCode.every((val) => val.po_type === poType)) {
        this.$vs.notify({
          color: "danger",
          title: "Clearing With DN",
          text: "Proposals must have the same PO type",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-x-circle",
        });
        return;
      }

      this.poType = poType;

      console.log("here gess");
      this.confirmation = true;
    },
    openModal() {
      if (this.checked.length === 0 || this.checkedCode.length === 0) {
        this.$vs.notify({
          color: "danger",
          title: "Clearing With DN",
          text: "Please select at least one shipment to be processed",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-x-circle",
        });
        return;
      }

      if (!this.validateSupplier()) {
        return;
      }

      if (
        this.checkedCode.some((val) => val.Type !== this.checkedCode[0].Type)
      ) {
        this.$vs.notify({
          color: "danger",
          title: "Clearing With DN",
          text: "Proposals must have the same PO type",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-x-circle",
        });
        return;
      }

      const poType = this.checkedCode[0].po_type;
      if (!this.checkedCode.every((val) => val.po_type === poType)) {
        this.$vs.notify({
          color: "danger",
          title: "Clearing With DN",
          text: "Proposals must have the same PO type",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-x-circle",
        });
        return;
      }

      this.poType = poType;

      this.checkedCode.sort((a, b) => a.PaidValue - b.PaidValue);
      this.isModalOpen = true;
    },
    closeModal(value) {
      this.isModalOpen = value;
      this.checked = [];
      this.checkedCode = [];
    },
    closeModalWithReload(value) {
      this.isModalOpen = value;
      this.checked = [];
      this.checkedCode = [];
      this.reloadData(this.params);
    },
    handleApprove(selected) {
      selected.class = "highlight";
      this.selectedData = selected;
      this.rejectPrompt = false;
      this.approvalPrompt = true;
    },
    dateFormat(date) {
      return moment(date).format("YYYY-MM-DD");
    },
    handleReject(selected) {
      selected.class = "highlight";
      this.selectedData = selected;
      this.approvalPrompt = false;
      this.rejectPrompt = true;
    },
    approve() {
      console.log(this.selectedData);
    },
    closeDetail() {
      this.activePrompt2 = false;
      this.detail = false;
      this.selectedData.class = "";
      this.selectedData = {
        ID: 0,
      };
    },
    closeReprintPrompt() {
      this.reprintPrompt = false;
      this.proposals = [];
      this.multipleReprint = false;
    },
    dataTable(params) {
      return new Promise((resolve, reject) => {
        this.$http
          .get("/api/v1/purchase-payment-proposal", {
            params: {
              status: 1,
              still_empty: 1,
              search: params.search,
              length: params.length,
              page: params.page,
              order: params.sort,
              sort: params.order,
              codes: params.codes,
            },
          })
          .then((r) => {
            resolve(r);
          })
          .catch((e) => {
            reject(e);
          });
      });
    },
    handleShow(selected) {
      this.selectedData.class = "";
      selected.class = "highlight";
      this.selectedData = selected;
      this.detail = true;
      this.poType = selected.po_type;
    },
    handleClose() {
      this.selectedData.class = "";
      this.detail = false;
      this.selectedData = {
        ID: 0,
      };
      this.reloadData(this.params);
      this.poType = "";
    },
    reloadData(params) {
      this.params = { ...params };
      this.params.codes = this.codes.map((v) => v.code);
      console.log(params);
      this.$vs.loading();

      const dataTable = this.dataTable(this.params);
      dataTable.then((r) => {
        console.log(r);
        if (r.code == 500) {
          this.$vs.loading.close();
        } else if (r.code == 200) {
          this.$vs.loading.close();
          this.data = r.data.purchasePaymentProposal;
          this.responseData = r.data;
          this.responseData.length = r.data.purchasePaymentProposal.length;
          // this.checkedAll = false;
        } else {
          this.$vs.loading.close();
        }
      });
    },
    updatePrintCount(purchase_payment_ids) {
      return this.$http.put(`/api/v1/purchase-payment-request/print-count`, {
        purchase_payment_ids,
      });
    },
    setPos(pageWidth, pos, offset) {
      if (pos >= pageWidth) {
        return pageWidth;
      } else {
        if (pos <= 0) {
          return offset;
        } else {
          return (pos += offset);
        }
      }
    },
    getProposalInfo(id) {
      return this.$http.get("/api/v1/purchase-payment-proposal/line/" + id);
    },
    getTaxnumber(data) {
      if (data.CreditNoteSupplierID === 0) {
        if (
          !data.linesInfo.purchaseInvoice.TaxReturnNumber &&
          !data.VendorInvoiceNumber
        ) {
          return "-";
        } else if (!data.VendorInvoiceNumber) {
          return data.linesInfo.purchaseInvoice.TaxReturnNumber;
        } else if (!data.linesInfo.purchaseInvoice.TaxReturnNumber) {
          return data.VendorInvoiceNumber;
        } else {
          const taxNumber = `${data.linesInfo.purchaseInvoice.TaxReturnNumber} - ${data.VendorInvoiceNumber}`;
          return taxNumber;
        }
      } else {
        return data.VendorInvoiceNumber;
      }
    },

    getTaxForMultipleDocument(proposal) {
      const invoiceVendor = this.getTaxnumber(proposal);
      const desc =
        proposal.CreditNoteSupplierID === 0
          ? proposal.linesInfo.purchaseInvoice.Description.replace(
              /^\n+|\n+$/g,
              ""
            )
          : proposal.linesInfo.creditNoteSupplier.description.replace(
              /^\n+|\n+$/g,
              ""
            );
      return `No Faktur Pajak : ${invoiceVendor}    Keterangan : ${desc}`;
    },

    generateSingleDocumentInfo(doc, data, startY, pageWidth) {
      console.log("data", data);
      const header = [
        { header: "", dataKey: "title" },
        { header: "", dataKey: "value" },
      ];
      // const invoice = data.linesInfo.purchaseInvoice.purchaseInvoice;
      const body = [
        {
          title: "No Dokumen",
          value:
            data.CreditNoteSupplierID === 0
              ? data.PurchaseInvoiceCodeInternal
              : data.linesInfo.creditNoteSupplier.code,
        },
        {
          title: "Tgl Dokumen",
          value: moment(
            data.CreditNoteSupplierID === 0
              ? data.PurchaseInvoiceCreatedAt
              : data.linesInfo.creditNoteSupplier.date
          ).format("DD.MM.YYYY"),
        },
        {
          title: "No Faktur Pajak",
          value: this.getTaxnumber(data),
        },
        //{ title: "Profit Center", value: "Profit Center" },
        { title: "BA", value: this.getBA(data.TerritoryID) },
        {
          title: "Tgl Jatuh Tempo",
          value: moment(data.DueDate).format("DD.MM.YYYY"),
        },
        {
          title: "Jumlah Tercetak",
          value: data.linesInfo.purchasePaymentProposal.PrintCount + 1,
        },
      ];
      autoTable(doc, {
        startY: startY + 0.2,
        columns: header,
        body: body,
        showHead: "never",
        styles: {
          lineWidth: 0.01,
          lineColor: [0, 0, 0],
          fontSize: 12,
          minCellHeight: 0.7,
          cellPadding: 0.2,
          font: "times",
        },
        columnStyles: {
          title: { cellWidth: 5 },
          value: { cellWidth: 5 },
        },
        theme: "plain",
        margin: { left: this.setPos(pageWidth, pageWidth - 10, 1), right: 1 },
        didParseCell(data) {
          data.cell.styles.fontSize = 11;
          const columnIndex = data.column.index;
          if (columnIndex === 0) {
            data.cell.styles.fontStyle = "bold";
          }
        },
      });

      return doc.lastAutoTable.finalY;
    },
    generateSingleDocumentInvoice(doc, data, startY, pageWidth) {
      const header = [
        { header: "Keterangan", dataKey: "title" },
        { header: "Nilai", dataKey: "value" },
      ];
      let body = [];
      let requestPayment;
      const paidValue = data.PaidValue;
      if (data.CreditNoteSupplierID === 0) {
        const invoice = data.linesInfo.purchaseInvoice;
        const invoiceLine = [...data.linesInfo.linePurchaseInvoice];
        const pph = invoiceLine.reduce((acc, val) => {
          if (invoice.IsSkb !== 1) {
            return acc + val.WhTax;
          } else {
            return acc;
          }
        }, 0);
        console.log("ini pph ges", pph);
        const ppn = invoice.VatExpired > 0 ? 0 : invoice.Tax;
        let beforeTax = invoice.TotalInvoiceValue - ppn;
        requestPayment = beforeTax + ppn - pph;
        const pad = 32;
        const tax = [
          `${
            invoice.CurrencyCode != undefined ? invoice.CurrencyCode : ""
          }${this.priceFormat(beforeTax).padStart(pad, " ")}`,
          `${this.priceFormat(ppn).padStart(pad, " ")}`,
          `${this.priceFormat(pph).padStart(pad, " ")}`,
        ];
        const subTotal = `${
          invoice.CurrencyCode != undefined ? invoice.CurrencyCode : ""
        }${this.priceFormat(requestPayment).padStart(pad, " ")}`;

        let debitNoteMemo = 0;
        let partialPayment = 0;
        let creditNoteMemo = 0;

        data.linesInfo.purchasePayments.forEach((payment) => {
          if (payment.DebitNoteID != 0) {
            debitNoteMemo += payment.PaidInvoiceValue;
          } else {
            partialPayment += payment.PaidInvoiceValue;
          }
        });

        const payment = [
          this.priceFormat(partialPayment).padStart(pad, " "),
          this.priceFormat(creditNoteMemo).padStart(pad, " "),
          this.priceFormat(debitNoteMemo).padStart(pad, " "),
        ];

        const other = [
          this.priceFormat(0).padStart(pad, " "),
          this.priceFormat(0).padStart(pad, " "),
        ];

        requestPayment =
          requestPayment - partialPayment - creditNoteMemo - debitNoteMemo;

        const total = `${
          invoice.CurrencyCode != undefined ? invoice.CurrencyCode : ""
        }${this.priceFormat(requestPayment).padStart(pad, " ")}`;

        body = [
          {
            title: "Nilai sebelum Pajak\nPPN\n-/- PPh",
            value: tax.join("\n"),
          },
          {
            title: "Sub Total",
            value: subTotal,
          },
          {
            title: "Partial Payment\nCredit Memo\nDebit Memo",
            value: payment.join("\n"),
          },
          {
            title: "Total",
            value: total,
          },
          {
            title: "Outstanding Balance\nRounding",
            value: other.join("\n"),
          },
          {
            title: "Payment Request",
            value: this.priceFormat(paidValue).padStart(pad, " "),
          },
        ];
      } else {
        const cnSupplier = data.linesInfo.creditNoteSupplier;
        // const cnSupplierLine = [...data.linesInfo.creditNoteSupplierLine];
        const pph = 0;
        console.log("ini pph ges", pph);
        const ppn = 0;
        let beforeTax = cnSupplier.total_credit - ppn;
        requestPayment = cnSupplier.total_credit + ppn - pph;
        const pad = 32;
        const tax = [
          `${"IDR"}${this.priceFormat(beforeTax).padStart(pad, " ")}`,
          `${this.priceFormat(ppn).padStart(pad, " ")}`,
          `${this.priceFormat(pph).padStart(pad, " ")}`,
        ];
        const subTotal = `${"IDR"}${this.priceFormat(requestPayment).padStart(
          pad,
          " "
        )}`;

        let debitNoteMemo = 0;
        let partialPayment = 0;
        let creditNoteMemo = 0;

        data.linesInfo.payments.forEach((payment) => {
          if (payment.DebitNoteID != 0) {
            debitNoteMemo += payment.PaidInvoiceValue;
          } else {
            partialPayment += payment.PaidInvoiceValue;
          }
        });

        const payment = [
          this.priceFormat(partialPayment).padStart(pad, " "),
          this.priceFormat(creditNoteMemo).padStart(pad, " "),
          this.priceFormat(debitNoteMemo).padStart(pad, " "),
        ];

        const other = [
          this.priceFormat(0).padStart(pad, " "),
          this.priceFormat(0).padStart(pad, " "),
        ];

        requestPayment =
          requestPayment - partialPayment - creditNoteMemo - debitNoteMemo;

        const total = `${"IDR"}${this.priceFormat(requestPayment).padStart(
          pad,
          " "
        )}`;

        body = [
          {
            title: "Nilai sebelum Pajak\nPPN\n-/- PPh",
            value: tax.join("\n"),
          },
          {
            title: "Sub Total",
            value: subTotal,
          },
          {
            title: "Partial Payment\nCredit Memo\nDebit Memo",
            value: payment.join("\n"),
          },
          {
            title: "Total",
            value: total,
          },
          {
            title: "Outstanding Balance\nRounding",
            value: other.join("\n"),
          },
          {
            title: "Payment Request",
            value: this.priceFormat(paidValue).padStart(pad, " "),
          },
        ];
      }

      autoTable(doc, {
        startY: startY + 0.2,
        columns: header,
        body: body,
        styles: {
          lineWidth: 0.0,
          lineColor: [0, 0, 0],
          fontSize: 12,
          minCellHeight: 0.7,
          cellPadding: 0.2,
          valign: "middle",
          halign: "right",
          font: "times",
        },
        headStyles: {
          fillColor: [219, 219, 219],
          lineWidth: 0.01,
          lineColor: [0, 0, 0],
          fontStyle: "bold",
          valign: "middle",
          halign: "center",
          cellPadding: 0.1,
          minCellHeight: 0.1,
          font: "times",
        },
        columnStyles: {
          title: { cellWidth: this.setPos(pageWidth, 12, 1) },
          value: { cellWidth: this.setPos(pageWidth, pageWidth - 14, 1) },
        },
        theme: "plain",
        margin: { left: 1, right: 1 },
        didParseCell(data) {
          data.cell.styles.fontSize = 11;
          const rowIndex = data.row.index;
          const columnIndex = data.column.index;
          const cellData = data.cell.raw;
          if (
            rowIndex % 2 !== 0 ||
            cellData.includes(header[columnIndex].header)
          ) {
            data.cell.styles.fontStyle = "bold";
          } else {
            data.cell.styles.fontStyle = "normal";
          }
        },
        didDrawCell(data) {
          const rowIndex = data.row.index;
          const columnIndex = data.column.index;
          const cellX = data.cell.x;
          const cellY = data.cell.y;
          if (rowIndex > 0 && columnIndex === 1) {
            doc.setLineWidth(0.01);
            doc.setDrawColor(0, 0, 0);
            doc.line(cellX + 2, cellY, cellX + data.cell.width, cellY);
          }
        },
      });

      return {
        startY: doc.lastAutoTable.finalY,
        paidValue,
      };
    },
    generateFooter(doc, startY, pageWidth, pageHeight) {
      const header = [
        { header: "Dibuat oleh:", dataKey: "a" },
        { header: "Diketahui", dataKey: "b" },
        { header: "Disetujui I:", dataKey: "c" },
        { header: "Disetujui II", dataKey: "d" },
      ];
      const width = this.setPos(pageWidth, pageWidth, 1) / 4;
      autoTable(doc, {
        startY: startY,
        columns: header,
        body: [{ a: "", b: "", c: "", d: "" }],
        styles: {
          lineWidth: 0.01,
          lineColor: [0, 0, 0],
          fontSize: 12,
          minCellHeight: 2,
          cellPadding: 0.2,
          font: "times",
        },
        headStyles: {
          fillColor: [219, 219, 219],
          lineWidth: 0.01,
          lineColor: [0, 0, 0],
          fontStyle: "bold",
          valign: "middle",
          halign: "center",
          cellPadding: 0.1,
          minCellHeight: 0.1,
          font: "times",
        },
        columnStyles: {
          1: { cellWidth: width },
          2: { cellWidth: width },
          3: { cellWidth: width },
          4: { cellWidth: width },
        },
        theme: "plain",
        margin: { left: 1, right: 1 },
        didParseCell(data) {
          data.cell.styles.fontSize = 11;
        },
      });

      let cursorY = doc.lastAutoTable.finalY;
      let diff = doc.lastAutoTable.finalY;
      cursorY -= 0.5;

      doc.setFont("times", "bold");
      doc.text(
        "Sudah dibayar dengan :",
        this.setPos(pageWidth, 0, 1),
        this.setPos(pageHeight, cursorY, 1)
      );
      cursorY += 0.5;

      doc.setFont("times", "normal");
      doc.text(
        "Cheque/BG No.",
        this.setPos(pageWidth, 0, 1),
        this.setPos(pageHeight, cursorY, 1)
      );

      doc.text(
        ":.........................",
        this.setPos(pageWidth, this.setPos(pageWidth, 4, 1), 1),
        this.setPos(pageHeight, cursorY, 1)
      );

      cursorY += 0.7;
      doc.setFont("times", "bold");
      doc.text(
        "Diterima oleh :",
        this.setPos(pageWidth, 0, 1),
        this.setPos(pageHeight, cursorY, 1)
      );

      cursorY += 0.5;
      doc.setFont("times", "normal");
      doc.text(
        "Nama",
        this.setPos(pageWidth, 0, 1),
        this.setPos(pageHeight, cursorY, 1)
      );
      doc.text(
        ":.........................",
        this.setPos(pageWidth, this.setPos(pageWidth, 4, 1), 1),
        this.setPos(pageHeight, cursorY, 1)
      );

      cursorY += 0.5;
      doc.text(
        "Tanggal",
        this.setPos(pageWidth, 0, 1),
        this.setPos(pageHeight, cursorY, 1)
      );
      doc.text(
        ":.........................",
        this.setPos(pageWidth, this.setPos(pageWidth, 4, 1), 1),
        this.setPos(pageHeight, cursorY, 1)
      );

      // const padding = this.multipleReprint ? pageHeight + 0.7 : 1;

      cursorY += 0.7;

      if (this.multipleReprint) {
        doc.text(
          "Tanda tangan",
          this.setPos(pageWidth, 0, 1),
          pageHeight + 0.7
        );
        doc.text(
          ":.........................",
          this.setPos(pageWidth, this.setPos(pageWidth, 4, 1), 1),
          pageHeight + 0.7
        );
      } else {
        doc.text(
          "Tanda tangan",
          this.setPos(pageWidth, 0, 1),
          this.setPos(pageHeight, cursorY, 1)
        );
        doc.text(
          ":.........................",
          this.setPos(pageWidth, this.setPos(pageWidth, 4, 1), 1),
          this.setPos(pageHeight, cursorY, 1)
        );
      }

      console.log("heigh", cursorY - diff);
    },
    generateMultipleDocumentInvoiceGrouping(
      doc,
      proposals,
      startY,
      pageWidth,
      pageHeight
    ) {
      const header = [
        { header: "No. Doc", dataKey: "docNumber" },
        { header: "Tgl Doc", dataKey: "docDate" },
        // { header: "PCtr", dataKey: "profitCenter" },
        { header: "BA", dataKey: "ba" },
        { header: "Tgl Jatuh Tempo", dataKey: "dueDate" },
        { header: "Nilai Sblm Pajak", dataKey: "beforeTaxValue" },
        { header: "PPN", dataKey: "ppn" },
        { header: "PPh", dataKey: "pph" },
        { header: "Payment Request", dataKey: "paymentRequest" },
      ];

      const body = [];
      let proposalIndex = 0;
      let totalRequestValue = 0;

      if (proposals[0].linesInfo.purchasePaymentProposal.CnSupplierID === 0) {
        for (const proposal of proposals) {
          const invoice = proposal.linesInfo.purchaseInvoice;
          const invoiceLine = [...proposal.linesInfo.linePurchaseInvoice];
          const pph = invoiceLine.reduce((acc, val) => {
            if (invoice.IsSkb !== 1) {
              return acc + val.WhTax;
            } else {
              return acc;
            }
          }, 0);
          const ppn = invoice.VatExpired > 0 ? 0 : invoice.Tax;
          const beforeTax = invoice.TotalInvoiceValue - ppn;
          // const paymentRequest = invoice.TotalInvoiceValue;
          let requestPayment = beforeTax + ppn - pph;

          let debitNoteMemo = 0;
          let partialPayment = 0;
          let creditNoteMemo = 0;

          proposal.linesInfo.purchasePayments.forEach((payment) => {
            if (payment.DebitNoteID != 0) {
              debitNoteMemo += payment.PaidInvoiceValue;
            } else {
              partialPayment += payment.PaidInvoiceValue;
            }
          });
          const paidValue = proposal.PaidValue;
          requestPayment =
            requestPayment - partialPayment - creditNoteMemo - debitNoteMemo;
          const tableData = {
            docNumber: proposal.PurchaseInvoiceCodeInternal,
            docDate: moment(proposal.PurchaseInvoiceCreatedAt).format(
              "DD.MM.YYYY"
            ),
            // profitCenter: "Profit Center",
            ba: this.getBA(proposal.TerritoryID),
            dueDate: moment(proposal.DueDate).format("DD.MM.YYYY"),
            beforeTaxValue: this.priceFormat(beforeTax),
            ppn: this.priceFormat(ppn),
            pph: this.priceFormat(pph),
            paymentRequest: this.priceFormat(paidValue),
          };
          totalRequestValue += paidValue;
          body.push(tableData);
          const invoiceNumber = {
            docNumber: this.getTaxForMultipleDocument(proposal),
          };
          body.push(invoiceNumber);
        }
      } else {
        for (const proposal of proposals) {
          const cnSupplier = proposal.linesInfo.creditNoteSupplier;
          const pph = 0;
          const ppn = 0;
          const beforeTax = cnSupplier.total_credit - ppn;
          // const paymentRequest = invoice.TotalInvoiceValue;
          let requestPayment = beforeTax + ppn - pph;

          let debitNoteMemo = 0;
          let partialPayment = 0;
          let creditNoteMemo = 0;

          proposal.linesInfo.purchasePayments.forEach((payment) => {
            if (payment.DebitNoteID != 0) {
              debitNoteMemo += payment.PaidInvoiceValue;
            } else {
              partialPayment += payment.PaidInvoiceValue;
            }
          });
          const paidValue = proposal.PaidValue;
          requestPayment =
            requestPayment - partialPayment - creditNoteMemo - debitNoteMemo;
          const tableData = {
            docNumber: cnSupplier.code,
            docDate: moment(cnSupplier.date).format("DD.MM.YYYY"),
            ba: this.getBA(proposal.TerritoryID),
            dueDate: moment(proposal.DueDate).format("DD.MM.YYYY"),
            beforeTaxValue: this.priceFormat(beforeTax),
            ppn: this.priceFormat(ppn),
            pph: this.priceFormat(pph),
            paymentRequest: this.priceFormat(paidValue),
          };

          totalRequestValue += paidValue;
          body.push(tableData);

          const invoiceNumber = {
            docNumber: this.getTaxForMultipleDocument(proposal),
          };
          body.push(invoiceNumber);
        }
      }

      const finalData = {
        docNumber: "Total",
        paymentRequest: this.priceFormat(totalRequestValue),
      };
      body.push(finalData);

      let startPoint = startY;
      let colWidth = 0;
      let isGreyedOut = false;
      let isShowHeader = true;
      let colHeight = 0;
      let bottomPadding = 8.5;
      const colSpan = this.setPos(pageWidth, pageWidth, 1);
      for (let i = 0; i < body.length; i++) {
        if (isShowHeader) {
          isShowHeader = false;
        }

        if (i % 2 === 0) {
          if (startPoint + 2 * colHeight > pageHeight + 2 - bottomPadding) {
            doc.addPage();
            doc.setPage(doc.internal.getNumberOfPages());
            startPoint = startY;
            isShowHeader = true;
          }
        }

        autoTable(doc, {
          startY: startPoint,
          columns: header,
          body: [body[i]],
          styles: {
            lineWidth: 0,
            lineColor: [0, 0, 0],
            fontSize: 12,
            minCellHeight: 0.1,
            cellPadding: 0.1,
            valign: "middle",
            halign: "center",
            font: "times",
          },
          showHead: i === 0 || isShowHeader ? "firstPage" : "never",
          headStyles: {
            fillColor: [219, 219, 219],
            lineWidth: 0.01,
            lineColor: [0, 0, 0],
            fontStyle: "bold",
            valign: "middle",
            cellPadding: 0.1,
            minCellHeight: 0.1,
            font: "times",
          },
          columnStyles: {
            docNumber: { cellWidth: 3.5 },
            docDate: { cellWidth: 2 },
            profitCenter: { cellWidth: 1.5 },
            ba: { cellWidth: 1.5 },
            dueDate: { cellWidth: 2 },
            beforeTaxValue: { cellWidth: 2.5 },
            ppn: { cellWidth: 2 },
            pph: { cellWidth: 2 },
          },
          theme: "plain",
          margin: { left: 1, right: 1 },
          // margin: { left: 1, right: 1, bottom: paddingBottom, top: startY },
          didParseCell(data) {
            data.cell.styles.fontSize = 9;
            const rowIndex = data.row.index;
            const columnIndex = data.column.index;
            const cellData = data.cell.raw
              ? data.cell.raw.toString()
              : "".toString();

            console.log("ini cellData", cellData);

            if (data.section === "head") {
              data.cell.styles.fontStyle = "bold";
              data.cell.styles.halign = "center";
            } else {
              if (i % 2 !== 0 && columnIndex === 0) {
                data.cell.colSpan = colSpan;
              } else if (rowIndex === body.length - 1) {
                data.cell.styles.fontStyle = "bold";
              }

              if (isGreyedOut) {
                isGreyedOut = false;
                data.cell.styles.fillColor = [188, 188, 188];
              }

              if (i % 2 === 0 && i < body.length - 1) {
                proposalIndex = Math.floor(i / 2);
                console.log("proposalIndex", proposalIndex);

                const printCount =
                  proposals[proposalIndex].linesInfo.purchasePaymentProposal
                    .PrintCount;
                if (printCount > 0) {
                  isGreyedOut = true;
                  data.cell.styles.fillColor = [188, 188, 188];
                } else {
                  data.cell.styles.fillColor = [255, 255, 255];
                }
              }

              if (i === body.length - 1) {
                data.cell.styles.fontStyle = "bold";
              }

              if (columnIndex > 4) {
                data.cell.styles.halign = "right";
              }
            }

            // if (
            //   cellData &&
            //   !cellData.includes(header[columnIndex].header) &&
            //   !cellData.includes(fakturPajak) &&
            //   rowIndex < body.length - 1
            // ) {
            //   if (rowIndex != rowIndexBefore) {
            //     proposalIndex = rowIndex / 2;
            //     rowIndexBefore = rowIndex;
            //     console.log("proposalIndex", proposalIndex);
            //   }

            //   const printCount =
            //     proposals[proposalIndex].linesInfo.purchasePaymentProposal
            //       .PrintCount;
            //   if (printCount > 0) {
            //     data.cell.styles.fillColor = [188, 188, 188];
            //     isGreyedOut = true;
            //   } else {
            //     data.cell.styles.fillColor = [255, 255, 255];
            //     isGreyedOut = false;
            //   }
            // } else if (
            //   cellData &&
            //   !cellData.includes(header[columnIndex].header) &&
            //   cellData.includes(fakturPajak) &&
            //   rowIndex < body.length - 1
            // ) {
            //   if (isGreyedOut) {
            //     data.cell.styles.fillColor = [188, 188, 188];
            //     isGreyedOut = false;
            //   } else {
            //     data.cell.styles.fillColor = [255, 255, 255];
            //   }
            // }

            // if (rowIndex === 0 && columnIndex === 0) {
            //   colWidth = data.cell.width;
            // }

            // if (rowIndex % 2 !== 0) {
            //   data.cell.colSpan = colSpan;
            // } else if (rowIndex === body.length - 1) {
            //   data.cell.styles.fontStyle = "bold";
            // }

            // if (columnIndex <= 4) {
            //   return;
            // }

            // if (!cellData || cellData.includes(header[columnIndex].header)) {
            //   return;
            // }
            // data.cell.styles.halign = "right";
          },
          didDrawCell(data) {
            const columnIndex = data.column.index;
            const rowIndex = data.row.index;
            const cellX = data.cell.x;
            const cellY = data.cell.y;
            if (columnIndex === 0) {
              startPoint += data.cell.height;
              colHeight = data.cell.height;
            }
            if (i === body.length - 1) {
              doc.setLineWidth(0.01);
              doc.setDrawColor(0, 0, 0);
              doc.line(cellX, cellY, cellX + data.cell.width, cellY);
            }
          },
        });
      }
      return {
        finalY: doc.lastAutoTable.finalY,
        firstColWidth: colWidth,
        totalRequestValue,
      };
    },
    generateMultipleDocumentInvoice(doc, proposals, startY, pageWidth) {
      const header = [
        { header: "No. Doc", dataKey: "docNumber" },
        { header: "Tgl Doc", dataKey: "docDate" },
        // { header: "PCtr", dataKey: "profitCenter" },
        { header: "BA", dataKey: "ba" },
        { header: "Tgl Jatuh Tempo", dataKey: "dueDate" },
        { header: "Nilai Sblm Pajak", dataKey: "beforeTaxValue" },
        { header: "PPN", dataKey: "ppn" },
        { header: "PPh", dataKey: "pph" },
        { header: "Payment Request", dataKey: "paymentRequest" },
      ];

      const body = [];
      let proposalIndex = 0;
      let totalInvoiceValue = 0;
      let isGreyedOut = false;
      let rowIndexBefore = 0;

      if (proposals[0].linesInfo.purchasePaymentProposal.CnSupplierID === 0) {
        for (const proposal of proposals) {
          const invoice = proposal.linesInfo.purchaseInvoice;
          const invoiceLine = [...proposal.linesInfo.linePurchaseInvoice];
          const pph = invoiceLine.reduce((acc, val) => {
            if (invoice.IsSkb !== 1) {
              return acc + val.WhTax;
            } else {
              return acc;
            }
          }, 0);
          const ppn = invoice.VatExpired > 0 ? 0 : invoice.Tax;
          const beforeTax = invoice.TotalInvoiceValue - ppn;
          // const paymentRequest = invoice.TotalInvoiceValue;
          let requestPayment = beforeTax + ppn - pph;

          let debitNoteMemo = 0;
          let partialPayment = 0;
          let creditNoteMemo = 0;

          proposal.linesInfo.purchasePayments.forEach((payment) => {
            if (payment.DebitNoteID != 0) {
              debitNoteMemo += payment.PaidInvoiceValue;
            } else {
              partialPayment += payment.PaidInvoiceValue;
            }
          });

          requestPayment =
            requestPayment - partialPayment - creditNoteMemo - debitNoteMemo;
          const tableData = {
            docNumber: proposal.PurchaseInvoiceCodeInternal,
            docDate: moment(proposal.PurchaseInvoiceCreatedAt).format(
              "DD.MM.YYYY"
            ),
            // profitCenter: "Profit Center",
            ba: this.getBA(proposal.TerritoryID),
            dueDate: moment(proposal.DueDate).format("DD.MM.YYYY"),
            beforeTaxValue: this.priceFormat(beforeTax),
            ppn: this.priceFormat(ppn),
            pph: this.priceFormat(pph),
            paymentRequest: this.priceFormat(requestPayment),
          };
          totalInvoiceValue += requestPayment;
          body.push(tableData);
          const invoiceNumber = {
            docNumber: this.getTaxForMultipleDocument(proposal),
          };
          body.push(invoiceNumber);
        }
      } else {
        for (const proposal of proposals) {
          const cnSupplier = proposal.linesInfo.creditNoteSupplier;
          const pph = 0;
          const ppn = 0;
          const beforeTax = cnSupplier.total_credit - ppn;
          // const paymentRequest = invoice.TotalInvoiceValue;
          let requestPayment = beforeTax + ppn - pph;

          let debitNoteMemo = 0;
          let partialPayment = 0;
          let creditNoteMemo = 0;

          proposal.linesInfo.purchasePayments.forEach((payment) => {
            if (payment.DebitNoteID != 0) {
              debitNoteMemo += payment.PaidInvoiceValue;
            } else {
              partialPayment += payment.PaidInvoiceValue;
            }
          });

          requestPayment =
            requestPayment - partialPayment - creditNoteMemo - debitNoteMemo;
          const tableData = {
            docNumber: cnSupplier.code,
            docDate: moment(cnSupplier.date).format("DD.MM.YYYY"),
            ba: this.getBA(proposal.TerritoryID),
            dueDate: moment(proposal.DueDate).format("DD.MM.YYYY"),
            beforeTaxValue: this.priceFormat(beforeTax),
            ppn: this.priceFormat(ppn),
            pph: this.priceFormat(pph),
            paymentRequest: this.priceFormat(requestPayment),
          };

          totalInvoiceValue += requestPayment;
          body.push(tableData);

          const invoiceNumber = {
            docNumber: this.getTaxForMultipleDocument(proposal),
          };
          body.push(invoiceNumber);
        }
      }

      const finalData = {
        docNumber: "Total",
        paymentRequest: this.priceFormat(totalInvoiceValue),
      };
      body.push(finalData);

      console.log("body gess", body);

      const colSpan = this.setPos(pageWidth, pageWidth, 1);
      let colWidth = 0;
      // let currentRowCount = 0;
      const paddingBottom = 8.5;
      // let currentY = 0;

      autoTable(doc, {
        startY: startY,
        columns: header,
        body: body,
        styles: {
          lineWidth: 0,
          lineColor: [0, 0, 0],
          fontSize: 12,
          minCellHeight: 0.1,
          cellPadding: 0.1,
          valign: "middle",
          halign: "center",
          font: "times",
        },
        showHead: "everyPage",
        headStyles: {
          fillColor: [219, 219, 219],
          lineWidth: 0.01,
          lineColor: [0, 0, 0],
          fontStyle: "bold",
          valign: "middle",
          cellPadding: 0.1,
          minCellHeight: 0.1,
          font: "times",
        },
        columnStyles: {
          docNumber: { cellWidth: 3.5 },
          docDate: { cellWidth: 2 },
          profitCenter: { cellWidth: 1.5 },
          ba: { cellWidth: 1.5 },
          dueDate: { cellWidth: 2 },
          beforeTaxValue: { cellWidth: 2.5 },
          ppn: { cellWidth: 2 },
          pph: { cellWidth: 2 },
        },
        theme: "plain",
        margin: { left: 1, right: 1, bottom: paddingBottom, top: startY },
        didParseCell(data) {
          data.cell.styles.fontSize = 9;
          const rowIndex = data.row.index;
          const columnIndex = data.column.index;
          const fakturPajak = "No Faktur Pajak";
          const cellData = data.cell.raw
            ? data.cell.raw.toString().trim()
            : "".toString();

          console.log("ini cellData", cellData);

          if (
            cellData &&
            !cellData.includes(header[columnIndex].header) &&
            !cellData.includes(fakturPajak) &&
            rowIndex < body.length - 1
          ) {
            if (rowIndex != rowIndexBefore) {
              proposalIndex = rowIndex / 2;
              rowIndexBefore = rowIndex;
              console.log("proposalIndex", proposalIndex);
            }

            const printCount =
              proposals[proposalIndex].linesInfo.purchasePaymentProposal
                .PrintCount;
            if (printCount > 0) {
              data.cell.styles.fillColor = [188, 188, 188];
              isGreyedOut = true;
            } else {
              data.cell.styles.fillColor = [255, 255, 255];
              isGreyedOut = false;
            }
          } else if (
            cellData &&
            !cellData.includes(header[columnIndex].header) &&
            cellData.includes(fakturPajak) &&
            rowIndex < body.length - 1
          ) {
            if (isGreyedOut) {
              data.cell.styles.fillColor = [188, 188, 188];
              isGreyedOut = false;
            } else {
              data.cell.styles.fillColor = [255, 255, 255];
            }
          }

          if (rowIndex === 0 && columnIndex === 0) {
            colWidth = data.cell.width;
          }

          if (rowIndex % 2 !== 0) {
            data.cell.colSpan = colSpan;
          } else if (rowIndex === body.length - 1) {
            data.cell.styles.fontStyle = "bold";
          }

          if (columnIndex <= 4) {
            return;
          }

          if (!cellData || cellData.includes(header[columnIndex].header)) {
            return;
          }
          data.cell.styles.halign = "right";
        },
        didDrawCell(data) {
          const rowIndex = data.row.index;
          const cellX = data.cell.x;
          const cellY = data.cell.y;
          // const cellData = data.cell.raw
          //   ? data.cell.raw.toString()
          //   : "".toString();
          // currentY += cellY * 0.0352778; //to cm
          // const fakturPajak = "No Faktur Pajak";

          // if (data.section === "body" && cellData.includes(fakturPajak)) {
          //   console.log("currentY", currentY);
          //   const rowHeight = data.row.height;
          //   const pageHeight = doc.internal.pageSize.height - 1;
          //   console.log("rowHeight", rowHeight);
          //   console.log("height", currentY + rowHeight * 2);
          //   console.log("buffer", pageHeight - paddingBottom - 1);
          //   // const pageHeight = doc.internal.pageSize.height - 2;
          //   if (currentY + rowHeight * 2 > pageHeight - paddingBottom - 1) {
          //     doc.addPage();
          //     currentY = 0;
          //     // if (rowIndex === body.length - 1) {
          //     //   doc.setLineWidth(0.01);
          //     //   doc.setDrawColor(0, 0, 0);
          //     //   doc.line(cellX, cellY, cellX + data.cell.width, cellY);
          //     // }
          //     // doc.setPage(doc.getNumberOfPages());
          //   }
          // }

          if (rowIndex === body.length - 1) {
            doc.setLineWidth(0.01);
            doc.setDrawColor(0, 0, 0);
            doc.line(cellX, cellY, cellX + data.cell.width, cellY);
          }
        },
      });
      return {
        finalY: doc.lastAutoTable.finalY,
        firstColWidth: colWidth,
        totalInvoiceValue,
      };
    },
    generateNumberSaying(value) {
      return angkaTerbilang(value).toUpperCase();
    },
    generateHeader(doc, startY, pageWidth, pageHeight, padding) {
      doc.setFontSize(16);
      doc.setFont("times", "bold");
      const companyName = "PT SINARMAS DISTRIBUSI NUSANTARA";
      const companyNameWidth = doc.getTextWidth(companyName);
      doc.text(
        companyName,
        this.setPos(pageWidth, (pageWidth - companyNameWidth) / 2, padding),
        this.setPos(pageHeight, startY, padding)
      );

      startY += 1;

      doc.setFontSize(12);
      doc.text(
        "Pembayaran Untuk / Kepada:",
        this.setPos(pageWidth, 0, padding),
        this.setPos(pageHeight, startY, padding)
      );

      doc.setFont("times", "normal");
      const supplier = this.proposals[0].linesInfo.supplier;
      const addr_lower = supplier.Address.toLowerCase().replace(
        /[^a-zA-Z0-9.,\-; ]/g,
        ""
      );
      const province_lower = supplier.Province.toLowerCase().replace(
        /[^a-zA-Z0-9.,\-; ]/g,
        ""
      );
      const supplierAddr =
        addr_lower && addr_lower.includes(province_lower)
          ? `(${supplier.Code}) - ${supplier.Name.toUpperCase().replace(
              /[^a-zA-Z0-9.,\-; ]/g,
              ""
            )}\n${supplier.Address.toUpperCase().replace(
              /[^a-zA-Z0-9.,\-; ]/g,
              ""
            )}`
          : `(${supplier.Code}) - ${supplier.Name.toUpperCase().replace(
              /[^a-zA-Z0-9.,\-; ]/g,
              ""
            )}\n${supplier.Address.toUpperCase().replace(
              /[^a-zA-Z0-9.,\-; ]/g,
              ""
            )}, ${supplier.SubDistrict.toUpperCase().replace(
              /[^a-zA-Z0-9.,\-; ]/g,
              ""
            )}, ${supplier.District.toUpperCase().replace(
              /[^a-zA-Z0-9.,\-; ]/g,
              ""
            )}, ${supplier.City.toUpperCase().replace(
              /[^a-zA-Z0-9.,\-; ]/g,
              ""
            )}, ${supplier.Province.toUpperCase().replace(
              /[^a-zA-Z0-9.,\-; ]/g,
              ""
            )}`;
      const supplierAddrLine = doc.splitTextToSize(
        supplierAddr,
        this.setPos(pageWidth, 7, padding)
      );

      startY += 0.5;

      doc.text(
        this.setPos(pageWidth, 0, padding),
        this.setPos(pageHeight, startY, padding),
        supplierAddrLine
      );

      return startY;
    },

    generateMultipleDocumentInfo(doc, cursorY, pageWidth, pageHeight, padding) {
      doc.text(
        `Tgl Dibuat : ${moment().format("DD.MM.YYYY")}`,
        this.setPos(
          pageWidth,
          this.setPos(pageWidth, pageWidth - 8, padding),
          padding
        ),
        this.setPos(pageHeight, cursorY, padding)
      );

      const height = this.calculateSupplierInfoHeight(doc, pageWidth, padding);
      cursorY += height - 0.5;

      const currencyCode =
        this.proposals[0].linesInfo.purchaseInvoice.CurrencyCode;
      console.log("Currency Code", currencyCode);
      const isSameCurrency = this.proposals.every(
        (v) => v.linesInfo.purchaseInvoice.CurrencyCode === currencyCode
      );

      if (!isSameCurrency) {
        throw new Error("Currency code is not the same");
      }

      doc.text(
        `Currency : ${currencyCode != undefined ? currencyCode : ""}`,
        this.setPos(
          pageWidth,
          this.setPos(pageWidth, pageWidth - 8, padding),
          padding
        ),
        this.setPos(pageHeight, cursorY, padding)
      );
    },

    generateOGPNumber(pdf, cursorY, pageWidth, pageHeight, padding) {
      pdf.setLineWidth(0.01);
      pdf.setFillColor(219, 219, 219);
      pdf.rect(
        this.setPos(pageWidth, 0, padding),
        this.setPos(pageHeight, 0, padding),
        this.setPos(pageWidth, pageWidth, padding),
        0.5,
        "FD"
      );

      cursorY += 0.4;

      pdf.setFont("times", "bold");
      pdf.text(
        "PERMOHONAN PEMBAYARAN",
        this.setPos(pageWidth, 0.5, padding),
        this.setPos(pageHeight, cursorY, padding)
      );

      pdf.text(
        "No. OGP",
        this.setPos(pageWidth, pageWidth - 6, padding),
        this.setPos(pageHeight, cursorY, padding)
      );

      return cursorY;
    },

    calculateSupplierInfoHeight(doc, pageWidth, padding) {
      const supplier = this.proposals[0].linesInfo.supplier;
      const supplierAddr = `(${
        supplier.Code
      }) - ${supplier.Name.toUpperCase()}, ${supplier.Address.toUpperCase()}, ${supplier.SubDistrict.toUpperCase()}, ${supplier.District.toUpperCase()}, ${supplier.City.toUpperCase()}, ${supplier.Province.toUpperCase()}`;
      const supplierAddrLine = doc.splitTextToSize(
        supplierAddr,
        this.setPos(pageWidth, 7, padding)
      );

      const dimension = doc.getTextDimensions(supplierAddrLine[0]);
      return dimension.h * supplierAddrLine.length + 1;
    },
    async printPDF(reprint = false) {
      try {
        // let proposals = [];

        if (!reprint) {
          if (this.checkedCode.length === 0) {
            this.$vs.notify({
              color: "danger",
              title: "Print",
              text: "Please select at least one proposal to be printed",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
            return;
          }

          if (!this.validateSupplier()) {
            return;
          }

          this.$vs.loading();

          this.proposals = JSON.parse(JSON.stringify(this.checkedCode));

          this.multipleReprint = this.proposals.length > 1;
          if (!this.multipleReprint) {
            const res = await this.getProposalInfo(this.proposals[0].ID);
            const data = res.data;
            this.proposals[0].linesInfo = data;
          } else {
            for (let i = 0; i < this.proposals.length; i++) {
              const res = await this.getProposalInfo(this.proposals[i].ID);
              const data = res.data;
              this.proposals[i].linesInfo = data;
            }
          }

          if (this.validateReprint(this.proposals)) {
            //validate if there is any proposal that has been printed
            this.$vs.loading.close();
            this.reprintPrompt = true;
            this.$nextTick(() => {
              const element = document.querySelector(".vs-dialog");
              element.style.width = "fit-content";
              element.style.minWitdh = "fit-content";
            });
            return;
          }
        }

        let cursorY = 0;
        const padding = 1;
        const height = 29.7;
        const width = 21;
        const pageWidth = width - padding * 2;
        const pageHeight = height - padding * 2;

        const pdf = new jsPDF({
          unit: "cm",
          format: [width, height],
        });
        pdf.setFontSize(12);
        console.log("props", this.proposals);

        // const company = proposals[0].linesInfo.company;

        // cursorY += 1;

        if (!this.multipleReprint) {
          cursorY = this.generateOGPNumber(
            pdf,
            cursorY,
            pageWidth,
            pageHeight,
            padding
          );
          cursorY += 1;
          cursorY = this.generateHeader(
            pdf,
            cursorY,
            pageWidth,
            pageHeight,
            padding
          );
        } else {
          cursorY += this.calculateSupplierInfoHeight(pdf, pageWidth, padding);
        }

        pdf.setFontSize(12);
        pdf.setFont("times", "normal");

        // pdf.setFontSize(12);
        // pdf.text(
        //   "Pembayaran Untuk / Kepada:",
        //   this.setPos(pageWidth, 0, padding),
        //   this.setPos(pageHeight, cursorY, padding)
        // );

        // const supplier = this.proposals[0].linesInfo.supplier;
        // const supplierAddr = `(${
        //   supplier.Code
        // }) - ${supplier.Name.toUpperCase()}, ${supplier.Address.toUpperCase()}, ${supplier.SubDistrict.toUpperCase()}, ${supplier.District.toUpperCase()}, ${supplier.City.toUpperCase()}, ${supplier.Province.toUpperCase()}`;
        // const supplierAddrLine = pdf.splitTextToSize(
        //   supplierAddr,
        //   this.setPos(pageWidth, 7, padding)
        // );
        // cursorY += 0.5;
        // pdf.setFont("times", "normal");
        // pdf.text(
        //   this.setPos(pageWidth, 0, padding),
        //   this.setPos(pageHeight, cursorY, padding),
        //   supplierAddrLine
        // );

        if (!this.multipleReprint) {
          cursorY = this.generateSingleDocumentInfo(
            pdf,
            this.proposals[0],
            cursorY,
            pageWidth
          );

          cursorY += 0.5;

          const result = this.generateSingleDocumentInvoice(
            pdf,
            this.proposals[0],
            cursorY,
            pageWidth
          );

          cursorY = result.startY;

          cursorY += 0.5;
          const totalValue = result.paidValue;
          // const currnecy = proposals[0].linesInfo.purchaseInvoice.CurrencyCode;

          const totalValueText =
            this.generateNumberSaying(totalValue) + " RUPIAH";
          const textValueHeight = pdf.getTextDimensions(totalValueText).h;
          const totalValueTextLine = pdf.splitTextToSize(
            totalValueText,
            this.setPos(pageWidth, 15, padding)
          );
          const title = "Terbilang: ";
          const titleWidth = pdf.getTextWidth(title);

          pdf.text(
            title,
            this.setPos(pageWidth, 0, padding),
            this.setPos(pageHeight, cursorY, padding)
          );
          pdf.text(
            this.setPos(pageWidth, titleWidth + 0.2, padding),
            this.setPos(pageHeight, cursorY, padding),
            totalValueTextLine
          );

          cursorY += textValueHeight * totalValueTextLine.length;

          const description =
            this.proposals[0].CreditNoteSupplierID === 0
              ? this.proposals[0].linesInfo.purchaseInvoice.Description.replace(
                  /^\n+|\n+$/g,
                  ""
                )
              : this.proposals[0].linesInfo.creditNoteSupplier.description.replace(
                  /^\n+|\n+$/g,
                  ""
                );

          cursorY += 0.15;
          pdf.text(
            `Keterangan: ${description ? description : "-"}`,
            this.setPos(pageWidth, 0, padding),
            this.setPos(pageHeight, cursorY, padding)
          );
          cursorY += 1.8;

          this.generateFooter(pdf, cursorY, pageWidth, pageHeight);
        } else {
          // cursorY -= 0.5;
          // const createdDate = this.proposals[0].PurchaseInvoiceCreatedAt;
          // pdf.text(
          //   `Tgl Dibuat : ${moment().format("DD.MM.YYYY")}`,
          //   this.setPos(
          //     pageWidth,
          //     this.setPos(pageWidth, pageWidth - 8, padding),
          //     padding
          //   ),
          //   this.setPos(pageHeight, cursorY, padding)
          // );

          // cursorY += this.calculateSupplierInfoHeight(pdf, pageWidth, padding);
          // const supplier = this.proposals[0].linesInfo.supplier;
          // const supplierAddr = `(${
          //   supplier.Code
          // }) - ${supplier.Name.toUpperCase()}, ${supplier.Address.toUpperCase()}, ${supplier.SubDistrict.toUpperCase()}, ${supplier.District.toUpperCase()}, ${supplier.City.toUpperCase()}, ${supplier.Province.toUpperCase()}`;
          // const supplierAddrLine = pdf.splitTextToSize(
          //   supplierAddr,
          //   this.setPos(pageWidth, 7, padding)
          // );

          // const dimension = pdf.getTextDimensions(supplierAddrLine[0]);
          // cursorY += dimension.h * supplierAddrLine.length + 1;
          cursorY += 3.3;

          //check wether CurrencyCode for proposal is all same
          // const currencyCode =
          //   this.proposals[0].linesInfo.purchaseInvoice.CurrencyCode;
          // console.log("Currency Code", currencyCode);
          // const isSameCurrency = this.proposals.every(
          //   (v) => v.linesInfo.purchaseInvoice.CurrencyCode === currencyCode
          // );

          // if (!isSameCurrency) {
          //   throw new Error("Currency code is not the same");
          // }

          // pdf.text(
          //   `Currency : ${currencyCode != undefined ? currencyCode : ""}`,
          //   this.setPos(
          //     pageWidth,
          //     this.setPos(pageWidth, pageWidth - 8, padding),
          //     padding
          //   ),
          //   this.setPos(pageHeight, cursorY, padding)
          // );

          // cursorY += 1.3;
          // const info = this.generateMultipleDocumentInvoice(
          //   pdf,
          //   this.proposals,
          //   cursorY,
          //   pageWidth
          // );

          const info = this.generateMultipleDocumentInvoiceGrouping(
            pdf,
            this.proposals,
            cursorY,
            pageWidth,
            pageHeight
          );

          cursorY = info.finalY;
          const totalValue = info.totalRequestValue;
          const totalValueText =
            this.generateNumberSaying(totalValue) + " RUPIAH";
          const totalValueTextLine = pdf.splitTextToSize(
            totalValueText,
            this.setPos(pageWidth, 15, padding)
          );

          cursorY -= 0.5;
          const title = "Terbilang: ";
          const titleWidth = pdf.getTextWidth(title);
          pdf.text(
            title,
            this.setPos(pageWidth, 0.4, padding),
            this.setPos(pageHeight, cursorY, padding)
          );
          pdf.text(
            this.setPos(pageWidth, titleWidth + 0.4, padding),
            this.setPos(pageHeight, cursorY, padding),
            totalValueTextLine
          );

          // const footerHeight = 9; //static
          // cursorY += 2;

          // this.generateFooter(pdf, cursorY, pageWidth, pageHeight);
          // const totalValue = info.totalInvoiceValue;
          // const totalValueText = this.generateNumberSaying(totalValue);
          // const totalValueTextLine = pdf.splitTextToSize(
          //   totalValueText,
          //   this.setPos(pageWidth, 15, padding)
          // );

          // const title = "Terbilang: ";
          // const titleWidth = pdf.getTextWidth(title);
          // pdf.text(
          //   title,
          //   this.setPos(pageWidth, 0.4, padding),
          //   this.setPos(pageHeight, cursorY, padding)
          // );
          // pdf.text(
          //   this.setPos(pageWidth, titleWidth + 0.4, padding),
          //   this.setPos(pageHeight, cursorY, padding),
          //   totalValueTextLine
          // );

          // cursorY += 1.8;
        }

        const pageCount = pdf.internal.getNumberOfPages();
        const time = moment().format("DD.MM.YY - HH:mm:ss");
        for (let i = 1; i <= pageCount; i++) {
          pdf.setPage(i);

          if (this.multipleReprint) {
            const multipleHeaderPos = 0.5;
            this.generateOGPNumber(pdf, 0, pageWidth, pageHeight, padding);
            this.generateHeader(
              pdf,
              this.setPos(pageHeight, multipleHeaderPos, padding),
              pageWidth,
              pageHeight,
              padding
            );
            this.generateMultipleDocumentInfo(
              pdf,
              this.setPos(pageHeight, multipleHeaderPos + 1, padding),
              pageWidth,
              pageHeight,
              padding
            );
            this.generateFooter(pdf, pageHeight - 5.5, pageWidth, pageHeight);
          }

          pdf.setFontSize(10);
          pdf.text(
            `Page ${i} of ${pageCount}${
              this.proposals.length > 1 ? "" : `    ${time}`
            }`,
            this.setPos(pageWidth, 0, padding),
            this.setPos(height, height - 0.5, 0)
          );
        }
        // pdf.save("proposal.pdf");
        // const pdfBlob = pdf.output("blob");
        // const pdfUrl = URL.createObjectURL(pdfBlob);
        // window.open(pdfUrl, "_blank");
        this.pdfProxyClass = pdf;
        this.pdfUrlPopup = pdf.output("dataurlstring");
        this.pdfUrlPopup += "#toolbar=0";
        this.showPdf = true;

        this.$vs.loading.close();
      } catch (e) {
        console.log(e);
        this.$vs.loading.close();
        this.checked = [];
        this.checkedCode = [];
      }
      // this.proposals = [];
    },
    getBA(territoryID) {
      const territory = [...this.$store.state.operatingUnit.filter.territory];
      const ter = territory.find((v) => v.TerritoryID === territoryID);
      return ter.TerritoryCode;
    },
    handleClosePopUp() {
      this.checked = [];
      this.checkedCode = [];
      this.showPdf = false;
      this.pdfUrlPopup = "";
      this.pdfProxyClass = null;
      this.proposals = [];
      this.usingCloseButton = true;
    },
    async handlePrintPDFPreview() {
      try {
        this.$vs.loading();

        const pp_ids = this.checkedCode.map((v) => v.ID);
        console.log("ID", pp_ids);
        let res = await this.updatePrintCount(pp_ids);
        if (res.code !== 200) {
          throw new Error("Failed to update print count");
        }

        res = await this.dataTable(this.params);
        if (res.code !== 200) {
          throw new Error("Failed to update print count");
        }

        console.log(this.pdfProxyClass);
        let name = "";

        if (this.checkedCode.length === 1) {
          const code =
            this.proposals[0].CreditNoteSupplierID === 0
              ? this.proposals[0].Code
              : this.proposals[0].linesInfo.creditNoteSupplier.code;
          name = `Payment Proposals_${code}.pdf`;
        } else {
          name = "Payment Proposals.pdf";
        }
        this.pdfProxyClass.save(name);

        this.showPdf = false;
        this.pdfUrlPopup = "";
        this.pdfProxyClass = null;
        this.checked = [];
        this.checkedCode = [];
        this.usingCloseButton = true;
        this.proposals = [];
        this.$vs.loading.close();
      } catch (e) {
        console.log(e);
        this.$vs.loading.close();
        this.$vs.notify({
          color: "danger",
          title: "Print",
          text: "Failed to print",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-x-circle",
        });
        this.checked = [];
        this.checkedCode = [];
      }
    },
    validateSupplier() {
      // SupplierCode, SupplierName
      if (this.checked.length === 1) {
        return true;
      }
      const temp = this.checkedCode[0];
      const validate = this.checkedCode.every(
        (v) =>
          temp.SupplierCode === v.SupplierCode &&
          temp.SupplierName === v.SupplierName
      );

      if (!validate) {
        this.$vs.notify({
          color: "danger",
          title: "Print",
          text: "Please select the same supplier",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-x-circle",
        });
        return false;
      }

      return true;
    },
    closeCreatedPaymentCode() {
      this.showPaymentCodePopup = false;
      this.createdPaymentCodes = [];
    },
    validateReprint(proposal = []) {
      return !proposal.every(
        (v) => v.linesInfo.purchasePaymentProposal.PrintCount === 0
      );
    },
  },
};
</script>
<style scoped>
.nonfixed {
  position: inherit;
  padding-left: 20px;
}
.core-enter-active {
  transition: all 0.3s ease;
}
.core-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}
.core-enter, .core-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}
.core {
  -webkit-transform-origin-y: all 1s ease;
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -o-transition: all 1s ease;
  transition: all 1s ease;
}
.vs-con-table .vs-con-tbody .vs-table--tbody-table {
  font-size: 12px;
}
.vertical-divider {
  border-right: 1px solid #7367f0;
  /* min-height: 800px; */
  /* height: 100%; */
  -webkit-mask-position-y: fixed;
  /* padding: 5px; */
}
.vs-con-table.stripe .tr-values:nth-child(2n) {
  background: beige;
}
.highlight > td {
  background-color: #d0cdf0;
}
.colored {
  border: 1px solid #7367f0;
  position: fixed;
  left: 4%;
  top: 40%;
  max-width: 45%;
  z-index: 999999999999;
  background: antiquewhite;
  background-color: antiquewhite;
}
.vs-dialog {
  max-width: 1000px;
}
</style>
